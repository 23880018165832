import React, { useEffect, useState } from "react";
import WivaNavBar from "../WIVANavBar/WivaNavBar";
import WIVASideNavBar from "../WIVASideNavBar/WIVASideNavBar";
import styles from "./WIVAHome.module.css";
import { isMobile } from "react-device-detect";
function WIVAHome() {
  const [loading, setloading] = useState(true);
  const [loadingCount, setloadingCount] = useState(0);

  useEffect(() => {}, []);
  let interval;
  useEffect(() => {
    if (!isMobile) {
      if (
        window.localStorage.getItem("firsttime") == "true" ||
        window.localStorage.getItem("firsttime") == null ||
        window.localStorage.getItem("firsttime") == undefined
      ) {
        document.getElementById("wivawelcomebtn").click();
      }
    }
  }, []);
  useEffect(() => {
    if (
      window.localStorage.getItem("firsttime") == "true" ||
      window.localStorage.getItem("firsttime") == null ||
      window.localStorage.getItem("firsttime") == undefined
    ) {
      interval = setInterval(() => {
        let count = loadingCount;
        if (loadingCount < 100) {
          {
            setloadingCount(parseInt(count) + 1);

            if (loadingCount >= 20 && loadingCount < 40) {
              document.getElementById("loadingfirstsection").className =
                styles["first-section"];
              document.getElementById("loadingsecondsection").className =
                styles["other-section"];
              document.getElementById("loadingthirdsection").className =
                styles["other-section"];

              document.getElementById("loadingfourthsection").className =
                styles["other-section"];
              document.getElementById("loadingbeforefirstsection").className =
                styles["other-section"];
            }
            if (loadingCount >= 40 && loadingCount < 60) {
              document.getElementById("loadingsecondsection").className =
                styles["first-section"];
              document.getElementById("loadingthirdsection").className =
                styles["other-section"];
              document.getElementById("loadingfirstsection").style.display =
                "none";
              document.getElementById("loadingfourthsection").className =
                styles["other-section"];
            }
            if (loadingCount >= 60 && loadingCount < 80) {
              document.getElementById("loadingsecondsection").className =
                styles["other-section"];
              document.getElementById("loadingthirdsection").className =
                styles["first-section"];
              document.getElementById("loadingfirstsection").className =
                styles["other-section"];
              document.getElementById("loadingfourthsection").className =
                styles["other-section"];
            }
            if (loadingCount >= 80) {
              document.getElementById("loadingsecondsection").className =
                styles["other-section"];
              document.getElementById("loadingthirdsection").className =
                styles["other-section"];
              document.getElementById("loadingfirstsection").className =
                styles["other-section"];
              document.getElementById("loadingfourthsection").className =
                styles["first-section"];
            }
          }
        } else {
          setloading(false);
          //   window.localStorage.setItem("firsttime", "false");
        }
      }, 600);
      return () => {
        clearInterval(interval);
      };
    } else {
      interval = setInterval(() => {
        let count = loadingCount;
        if (loadingCount < 100) {
          {
            setloadingCount(parseInt(count) + 1);

            if (loadingCount >= 20 && loadingCount < 40) {
              document.getElementById("loadingfirstsection").className =
                styles["first-section"];
              document.getElementById("loadingsecondsection").className =
                styles["other-section"];
              document.getElementById("loadingthirdsection").className =
                styles["other-section"];

              document.getElementById("loadingfourthsection").className =
                styles["other-section"];
              document.getElementById("loadingbeforefirstsection").className =
                styles["other-section"];
            }
            if (loadingCount >= 40 && loadingCount < 60) {
              document.getElementById("loadingsecondsection").className =
                styles["first-section"];
              document.getElementById("loadingthirdsection").className =
                styles["other-section"];
              document.getElementById("loadingfirstsection").style.display =
                "none";
              document.getElementById("loadingfourthsection").className =
                styles["other-section"];
            }
            if (loadingCount >= 60 && loadingCount < 80) {
              document.getElementById("loadingsecondsection").className =
                styles["other-section"];
              document.getElementById("loadingthirdsection").className =
                styles["first-section"];
              document.getElementById("loadingfirstsection").className =
                styles["other-section"];
              document.getElementById("loadingfourthsection").className =
                styles["other-section"];
            }
            if (loadingCount >= 80) {
              document.getElementById("loadingsecondsection").className =
                styles["other-section"];
              document.getElementById("loadingthirdsection").className =
                styles["other-section"];
              document.getElementById("loadingfirstsection").className =
                styles["other-section"];
              document.getElementById("loadingfourthsection").className =
                styles["first-section"];
            }
          }
        } else {
          setloading(false);
          //   window.localStorage.setItem("firsttime", "false");
        }
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }
  }, [loadingCount]);

  return (
    <div className={styles["container-fluid"] + " "}>
      <div className={styles["row"] + " row"}>
        <div className='col-md-12 p-0'>
          {loading ? (
            <>
              <div className={styles["wiv-joinOuterContainer"]}>
                {window.localStorage.getItem("firsttime") == "false" ? (
                  <div className={styles["header-div"]}>
                    <div
                      className={styles["skipDiv"]}
                      onClick={() => {
                        // window.localStorage.setItem("firsttime", "false");

                        setloadingCount(100);
                      }}
                    >
                      <span className={styles["skip-text"]}>SKIP</span>
                      <img src='/earth/skip-arrow.svg'></img>
                    </div>
                  </div>
                ) : null}
                <div className={styles["loading-bar-div"]}>
                  <span className={styles["main-text"]}>
                    {" "}
                    Welcome to Women in _VO
                    <span className={styles["main-text-bold"]}>IS</span> Arena
                    (WIVA)
                  </span>

                  <label className={styles["sub-main-text"]}>
                    Loading .. {loadingCount} %
                  </label>

                  <div
                    class='progress mt-2'
                    style={{
                      width: "80%",
                      height: "0.5rem",
                      backgroundColor: "#CCCCCC",
                    }}
                  >
                    <div
                      class='progress-bar'
                      role='progressbar'
                      style={{
                        width: `${loadingCount}%`,
                        backgroundColor: "#BE0000",
                        fontFamily: "Polaris !important",
                      }}
                      aria-valuenow={`${loadingCount}`}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    ></div>
                  </div>
                </div>
                {isMobile ? (
                  <div
                    id='loadingbeforefirstsection'
                    className={styles["first-section"]}
                  >
                    <img
                      src='/wiv/InstructionNavigate.svg'
                      className={styles["img"]}
                    ></img>
                    <span className={styles["wiv-tip-text"]}>
                      Navigate around the hall by dragging{" "}
                      <span className={styles["wiv-tip-text-bolder"]}>
                        The Arrows{" "}
                      </span>
                      button all around.
                    </span>
                  </div>
                ) : (
                  <div
                    id='loadingbeforefirstsection'
                    className={styles["first-section"]}
                  >
                    <img
                      src='/wiv/instructionmouse0.svg'
                      className={styles["img"]}
                    ></img>
                    <span className={styles["wiv-tip-text"]}>
                      Move around the 3D platform by pressing the or using{" "}
                      <span className={styles["wiv-tip-text-bolder"]}>
                        arrow keys{" "}
                      </span>
                      the{" "}
                      <span className={styles["wiv-tip-text-bolder"]}>
                        WASD
                      </span>{" "}
                      keys.
                    </span>
                  </div>
                )}
                {isMobile ? (
                  <div
                    id='loadingfirstsection'
                    className={styles["other-section"]}
                  >
                    <img
                      src='/wiv/InstructionRotate.svg'
                      className={styles["img"]}
                    ></img>
                    <span className={styles["wiv-tip-text"]}>
                      Explore event environment by dragging
                      <span className={styles["wiv-tip-text-bolder"]}>
                        {" "}
                        Right and left
                      </span>{" "}
                      to navigate.
                    </span>
                  </div>
                ) : (
                  <div
                    id='loadingfirstsection'
                    className={styles["other-section"]}
                  >
                    <img
                      src='/wiv/instructionmouse2.svg'
                      className={styles["img"]}
                    ></img>
                    <span className={styles["wiv-tip-text"]}>
                      Explore information about any clickable link by
                      <span className={styles["wiv-tip-text-bolder"]}>
                        {" "}
                        left-clicking
                      </span>{" "}
                      on them.
                    </span>
                  </div>
                )}{" "}
                {isMobile ? (
                  <div
                    id='loadingsecondsection'
                    className={styles["other-section"]}
                  >
                    <img
                      src='/wiv/instructionTouch.svg'
                      className={styles["img"]}
                    ></img>
                    <span className={styles["wiv-tip-text"]}>
                      Explore information about any clickable link by{" "}
                      <span className={styles["wiv-tip-text-bolder"]}>
                        Long Press Touch
                      </span>{" "}
                      on them.
                    </span>
                  </div>
                ) : (
                  <div
                    id='loadingsecondsection'
                    className={styles["other-section"]}
                  >
                    <img src='/wiv/Mouse.svg' className={styles["img"]}></img>
                    <span className={styles["wiv-tip-text"]}>
                      Explore the 3D platform by rotating and looking around
                      using the{" "}
                      <span className={styles["wiv-tip-text-bolder"]}>
                        left-clicking and drag
                      </span>
                      .
                    </span>
                  </div>
                )}
                <div
                  id='loadingthirdsection'
                  className={styles["other-section"]}
                >
                  <img
                    src='/wiv/instructionmouse3.svg'
                    className={styles["img"]}
                  ></img>
                  <span className={styles["wiv-tip-text"]}>
                    Access detailed information by{" "}
                    <span className={styles["wiv-tip-text-bolder"]}>
                      clicking
                    </span>{" "}
                    the information icon.
                  </span>
                </div>
                <div
                  id='loadingfourthsection'
                  className={styles["other-section"]}
                >
                  <img
                    src='/wiv/instructionmouse4.svg'
                    className={styles["img"]}
                  ></img>
                  <span className={styles["wiv-tip-text"]}>
                    Navigate to any location on the event by{" "}
                    <span className={styles["wiv-tip-text-bolder"]}>
                      clicking on the map icon
                    </span>
                    .
                  </span>
                </div>
                <div className={styles["loading-footer"]}>
                  <label className={styles["footer-text"]}>
                    {" "}
                    For a better experience, make sure you have a stable
                    internet connection and aren’t connected to the VPN.
                  </label>
                  <label className={styles["footer-text"]}>
                    {" "}
                    If you face technical issues, please contact us via
                    <a
                      href='mailto:envirtual@vodafone.com'
                      target='_blank'
                      style={{ color: "#e60000" }}
                    >
                      {" "}
                      envirtual@vodafone.com
                    </a>
                    .
                  </label>
                  <label className={styles["footer-bold-main-text"]}>
                    © Brought to you by
                  </label>

                  <label className={styles["footer-text"]}>
                    <a
                      href='https://envirtual.tvois.vodafone.com/showroom'
                      target='_blank'
                      style={{ color: "#e60000" }}
                    >
                      Tech _VO<strong>IS</strong> - IT Platforms & Automation
                      GSL
                    </a>
                  </label>
                </div>
              </div>
            </>
          ) : null}
          <>
            <WivaNavBar></WivaNavBar>
            <WIVASideNavBar></WIVASideNavBar>

            <iframe
              scrolling='no'
              id='iframe'
              src='/events/wiva/index.html'
              className={styles["iframe"]}
              crossorigin
            ></iframe>
          </>
        </div>
      </div>
    </div>
  );
}

export default WIVAHome;
